import { type Table, flexRender } from '@tanstack/react-table';
import { useRouter } from 'app/navigation';
import classNames from 'classnames';
import type { Route } from 'next';
import { useTranslations } from 'next-intl';

export default function TableRows<T, K extends string>({
  table,
  onRowClick,
  rowHref,
}: {
  table: Table<T>;
  onRowClick?: (row: T) => void;
  rowHref?: (row: T) => Route<K> | null;
}) {
  const router = useRouter();
  const t = useTranslations();

  if (table.getRowModel().rows.length === 0) {
    return (
      <tr>
        <td
          colSpan={table.getVisibleFlatColumns().length}
          className="px-2 py-3 text-center"
        >
          {t('Table.Row.noEntries')}
        </td>
      </tr>
    );
  }

  return table.getRowModel().rows.map((row) => {
    const resolvedRowHref = rowHref?.(row.original);
    return (
      // Apply the row props
      <tr
        key={row.id}
        className={classNames(
          'border-b border-gray-200',
          resolvedRowHref || onRowClick
            ? 'cursor-pointer hover:bg-gray-50'
            : '',
        )}
        // Make keyboard-selectable if interactive
        role={resolvedRowHref || onRowClick ? 'button' : undefined}
        onClick={(e) => {
          if (!resolvedRowHref) {
            if (onRowClick) {
              onRowClick(row.original);
            }
            return;
          }
          e.ctrlKey || e.metaKey
            ? window.open(resolvedRowHref)
            : router.push(resolvedRowHref);
        }}
      >
        {
          // Loop over the rows cells
          row
            .getVisibleCells()
            .map((cell) => (
              <td
                key={cell.id}
                className={classNames(
                  'bg-white px-2 py-3',
                  cell.column.columnDef.meta?.className,
                  // Show group borders
                  cell.column.parent &&
                    cell.column.id === cell.column.parent.columns.at(0)?.id &&
                    'border-l border-gray-200',
                  cell.column.parent &&
                    cell.column.id === cell.column.parent.columns.at(-1)?.id &&
                    'border-r border-gray-200',
                  // Pinned columns
                  cell.column.getIsPinned() === 'left'
                    ? 'bg-white sticky left-0'
                    : '',
                  cell.column.getIsPinned() === 'right'
                    ? 'bg-white sticky right-0'
                    : '',
                )}
              >
                {cell.column.getIsPinned() && (
                  <div
                    className={classNames(
                      'absolute inset-0 border-gray-300',
                      cell.column.getIsPinned() === 'right'
                        ? 'border-l'
                        : 'border-r',
                    )}
                  />
                )}
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </td>
            ))
        }
      </tr>
    );
  });
}
