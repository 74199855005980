import { type Table, flexRender } from '@tanstack/react-table';
import classNames from 'classnames';

export default function TableFooter<T>({ table }: { table: Table<T> }) {
  if (
    table.getAllFlatColumns().filter((column) => !!column.columnDef.footer)
      .length === 0
  ) {
    // Don't render if no columns have footers
    return null;
  }

  return (
    <tfoot className="divide-y divide-gray-200 border-t border-gray-200">
      {table.getFooterGroups().map((footerGroup) => (
        <tr key={footerGroup.id} className="h-9">
          {footerGroup.headers.map((footer) => (
            <th
              key={footer.id}
              className={classNames(
                'px-2 py-3 text-left font-medium text-gray-400',
                // Pinned columns
                footer.column.getIsPinned() === 'left'
                  ? 'bg-white h-9 sticky left-0'
                  : '',
                footer.column.getIsPinned() === 'right'
                  ? 'bg-white h-9 sticky right-0'
                  : '',
                footer.column.columnDef.meta?.className,
              )}
            >
              {footer.column.getIsPinned() && (
                <div
                  className={classNames(
                    'absolute inset-0 border-gray-300',
                    footer.column.getIsPinned() === 'right'
                      ? 'border-l'
                      : 'border-r',
                  )}
                />
              )}
              {footer.isPlaceholder
                ? null
                : flexRender(
                    footer.column.columnDef.footer,
                    footer.getContext(),
                  )}
            </th>
          ))}
        </tr>
      ))}
    </tfoot>
  );
}
